import './App.css'
import { Canvas, useFrame } from '@react-three/fiber'
import React, { useRef} from 'react'
import {Link} from 'react-router-dom'
import { useEffect, useState, useCallback } from 'react'
import ImageViewer from 'react-simple-image-viewer';
import oxg from './res/oxygen.png'

function Oxygen(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
      oxg
    ];

    const openImageViewer = useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };

    useEffect(() => {
      props.changeColor(0.12, 0.59, 0.67);
      document.getElementById("main-bg").classList.add("oxygen-bg");
    }, [])

    return (
      <div className="hero-left">
          <h1 className="hero-title">Oxygen</h1>
          <p className="hero-sub">Oxygen is a cross-platform text and code editor made with Java. Oxygen supports many standard code editor features such as multi-language syntax highlighting, line numbering, find & replace, and much more. Additionally, Oxygen has customizable themes and fonts, as well as a built-in terminal emulator and project explorer.</p>
          <div className="hero-actions hero-gallery">
              <div className="gallery">
                {images.map((src, index) => (
                  <img
                    src={ src }
                    onClick={ () => openImageViewer(index) }
                    key={ index }
                    style={{ margin: '2px' }}
                    alt=""
                    className='gallery-img'
                  />
                ))}

                {isViewerOpen && (
                  <ImageViewer
                    src={ images }
                    currentIndex={ currentImage }
                    disableScroll={ false }
                    closeOnClickOutside={ true }
                    onClose={ closeImageViewer }
                  />
                )}
            </div>

            <div className="action-container">
              <Link to="/projects"><div className="theme-button">Back to Projects</div></Link>
            </div>
          </div>
      </div>
    );
  }
  
  export default Oxygen;