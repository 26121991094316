import './App.css'
import { Canvas, useFrame } from '@react-three/fiber'
import React, { useRef} from 'react'
import {Link} from 'react-router-dom'
import { useEffect, useState, useCallback } from 'react'
import ImageViewer from 'react-simple-image-viewer';
import xtz from './res/xtz.png'

function Exttasy(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
      xtz
    ];

    const openImageViewer = useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };

    useEffect(() => {
      props.changeColor(0.86, 0.16, 0.82);
      document.getElementById("main-bg").classList.add("exttasy-bg");
    }, [])

    return (
      <div className="hero-left">
          <h1 className="hero-title">exTTasY</h1>
          <p className="hero-sub">exTTasY is a hardware accelerated terminal emulator for Windows made with C++ and SDL. It supports most 'private mode' ANSI codes, 256-bit colour, 'raw mode' applications (e.g. Vim) and much more. exTTasY uses <span className="p-link"><Link to="/opium">Opium</Link></span> as its default shell.</p>
          <div className="hero-actions hero-gallery">
              <div className="gallery">
                {images.map((src, index) => (
                  <img
                    src={ src }
                    onClick={ () => openImageViewer(index) }
                    key={ index }
                    style={{ margin: '2px' }}
                    alt=""
                    className='gallery-img'
                  />
                ))}

                {isViewerOpen && (
                  <ImageViewer
                    src={ images }
                    currentIndex={ currentImage }
                    disableScroll={ false }
                    closeOnClickOutside={ true }
                    onClose={ closeImageViewer }
                  />
                )}
            </div>

            <div className="action-container">
              <Link to="/projects"><div className="theme-button">Back to Projects</div></Link>
            </div>
          </div>
      </div>
    );
  }
  
  export default Exttasy;