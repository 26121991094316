import './App.css'
import { Canvas, useFrame } from '@react-three/fiber'
import React, { useRef} from 'react'
import {Link} from 'react-router-dom'
import { useEffect, useState, useCallback } from 'react'
import ImageViewer from 'react-simple-image-viewer';
import opm from './res/opium.png'

function Opium(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
      opm
    ];

    const openImageViewer = useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };

    useEffect(() => {
      props.changeColor(0.86, 0.16, 0.39);
      document.getElementById("main-bg").classList.add("opium-bg");
    }, [])

    return (
      <div className="hero-left">
          <h1 className="hero-title">Opium</h1>
          <p className="hero-sub">Opium is a shell written in C, designed for use with Windows. Opium supports various features such as stream redirection, command aliasing, wildcard pattern matching, and much more. Additionally, Opium is an interpreter for its own custom scripting language 'Rx'.</p>
          <div className="hero-actions hero-gallery">
              <div className="gallery">
                {images.map((src, index) => (
                  <img
                    src={ src }
                    onClick={ () => openImageViewer(index) }
                    key={ index }
                    style={{ margin: '2px' }}
                    alt=""
                    className='gallery-img'
                  />
                ))}

                {isViewerOpen && (
                  <ImageViewer
                    src={ images }
                    currentIndex={ currentImage }
                    disableScroll={ false }
                    closeOnClickOutside={ true }
                    onClose={ closeImageViewer }
                  />
                )}
            </div>

            <div className="action-container">
              <Link to="/projects"><div className="theme-button">Back to Projects</div></Link>
            </div>
          </div>
      </div>
    );
  }
  
  export default Opium;