import { Link } from "react-router-dom"
import { useEffect } from "react"

function Projects(props){
    useEffect(() => {
        props.changeColor(0.3, 0.2, 0.8)
        document.getElementById("main-bg").classList.remove("starfire-bg", "novacaine-bg", "exttasy-bg", "opium-bg", "oxygen-bg",)
    }, [])
    
    return (
        <div className="hero-left">
            <h1 className="hero-title">Projects</h1>

            <Link to="/starfire">
                <div className="project-item" id="starfire" onMouseEnter={() => props.changeColor(0.86, 0.51, 0.16)} onMouseLeave={() => props.changeColor(0.3, 0.2, 0.8)}>
                    <p className="project-item-title">Starfire</p>
                    <p className="project-item-sub">3D Rendering Engine powered by DirectX 12</p>
                </div>
            </Link>

            <Link to="/novacaine">
                <div className="project-item" id="novacaine" onMouseEnter={() => props.changeColor(0.59, 0.55, 0.86)} onMouseLeave={() => props.changeColor(0.3, 0.2, 0.8)}>
                    <p className="project-item-title">Novacaine</p>
                    <p className="project-item-sub">64-bit imperative programming language</p>
                </div>
            </Link>

            <Link to="/exttasy">
                <div className="project-item" id="exttasy" onMouseEnter={() => props.changeColor(0.86, 0.16, 0.82)} onMouseLeave={() => props.changeColor(0.3, 0.2, 0.8)}>
                    <p className="project-item-title">exTTasY</p>
                    <p className="project-item-sub">Hardware accelerated terminal emulator</p>
                </div>
            </Link>

            <Link to="/opium">
                <div className="project-item" id="opium" onMouseEnter={() => props.changeColor(0.86, 0.16, 0.39)} onMouseLeave={() => props.changeColor(0.3, 0.2, 0.8)}>
                    <p className="project-item-title">Opium</p>
                    <p className="project-item-sub">Shell and scripting language for Windows</p>
                </div>
            </Link>

            <Link to="/oxygen">
                <div className="project-item" id="oxygen" onMouseEnter={() => props.changeColor(0.12, 0.59, 0.67)} onMouseLeave={() => props.changeColor(0.3, 0.2, 0.8)}>
                    <p className="project-item-title">Oxygen</p>
                    <p className="project-item-sub">Minimal code editor</p>
                </div>
            </Link>
            


            <div className="hero-actions">
            <Link to="/"><div className="theme-button">Back to Home</div></Link>
            </div>
        </div>        
    )
}

export default Projects
