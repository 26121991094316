import './App.css'
import React, { useRef} from 'react'
import {Link} from 'react-router-dom'

function NotFound() {
    return (
      <div className="hero-left">
          <h1 className="hero-title">404</h1>
          <p className="hero-sub">Page not found</p>
          <div className="hero-actions hero-gallery">
            <div className="action-container">
              <Link to="/"><div className="theme-button">Back to Home</div></Link>
            </div>
          </div>
      </div>
    );
  }
  
  export default NotFound;