import './App.css'
import { Canvas, useFrame } from '@react-three/fiber'
import React, { useRef} from 'react'
import {Link} from 'react-router-dom'
import { useEffect, useState, useCallback} from 'react'
import ImageViewer from 'react-simple-image-viewer';
import sf1 from './res/sf1.bmp'
import sf2 from './res/sf2.bmp'
import sf3 from './res/sf3.bmp'
import sf4 from './res/sf4.bmp'
import sf5 from './res/sf5.bmp'

function Starfire(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
      sf1,
      sf2,
      sf3,
      sf4,
      sf5
    ];

    const openImageViewer = useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);
  
    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };

    useEffect(() => {
      props.changeColor(0.86, 0.51, 0.16);
      document.getElementById("main-bg").classList.add("starfire-bg");
    }, [])

    return (
      <div className="hero-left">
          <h1 className="hero-title">Starfire</h1>
          <p className="hero-sub">Starfire is a 3D rendering engine and level editor made with C++ and DirectX 12. Starfire provides various modern rendering techniques such as dynamic lighting and shadows, screen space reflections, post-processing (tonemapping, bloom, etc...) and much more.</p>
          <div className="hero-actions hero-gallery">
              <div className="gallery">
                {images.map((src, index) => (
                  <img
                    src={ src }
                    onClick={ () => openImageViewer(index) }
                    width="300"
                    key={ index }
                    style={{ margin: '2px' }}
                    alt=""
                    className='gallery-img'
                  />
                ))}

                {isViewerOpen && (
                  <ImageViewer
                    src={ images }
                    currentIndex={ currentImage }
                    disableScroll={ false }
                    closeOnClickOutside={ true }
                    onClose={ closeImageViewer }
                  />
                )}
            </div>

            <div className="action-container">
              <Link to="/projects"><div className="theme-button">Back to Projects</div></Link>
            </div>
          </div>
      </div>
    );
  }
  
  export default Starfire;