import React, { useRef, useState, useMemo} from 'react'
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Landing from './Landing';
import Projects from './Projects';
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import Contact from './Contact';
import Starfire from './Starfire';
import Novacaine from './Novacaine';
import Exttasy from './Exttasy';
import Opium from './Opium';
import Oxygen from './Oxygen';
import { useDrag } from 'react-use-gesture'
import * as THREE from 'three'
import { a, useSpring } from '@react-spring/three'
import NotFound from './NotFound';


  const vertexShader =  `
    precision mediump float;
    varying vec2 vUv;
    void main() {
        vec4 mvPosition = modelViewMatrix * vec4(position, 1.);
        gl_Position = projectionMatrix * mvPosition;
        vUv = uv;
    }
  `;

  const fragmentShader =  `
    varying vec2 vUv;
    uniform float u_time;
    uniform vec3 color;

    void main() {
      vec2 uv = (2.0 * vUv) / 0.3;

      for(float i = 1.0; i < 5.0; i++){
        uv.x += 0.5 / i * cos(i * 2.5 * uv.y + u_time);
        uv.y += 0.5 / i * cos(i * 2.5 * uv.x + u_time);
      }

      gl_FragColor = vec4(vec3(0.1)/abs(sin(u_time-uv.y-uv.x)), 0.1/abs(sin(u_time-uv.y-uv.x)));

      gl_FragColor *= vec4(color, 1.0);

      //gl_FragColor = vec4(uv.x, uv.y, 1.0, 1.0);

    }
  `;
function Inspector({ responsiveness = 1, children }) {
  const { size } = useThree()
  const euler = useMemo(() => new THREE.Euler(), [])
  const [spring, set] = useSpring(() => ({
    rotation: [0, 0, 0],
  }))
  const bind = useDrag(({ delta: [dx, dy] }) => {
    euler.y += (dx / size.width) * responsiveness
    euler.x += (dy / size.width) * responsiveness
    euler.x = THREE.MathUtils.clamp(euler.x, -Math.PI / 2, Math.PI / 2)
    set({ rotation: euler.toArray().slice(0, 3) })
  })
  return (
    <a.group {...bind()} {...spring}>
      {children}
    </a.group>
  )
}

function Sphere(props) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
 // const [clicked, click] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => {ref.current.rotation.x += delta * 0.2; ref.current.rotation.z += delta * 0.2})
  // Return the view, these are regular Threejs elements expressed in JSX
  useFrame(({ clock }) => {
    ref.current.material.uniforms.u_time.value = clock.oldTime * 0.0003;
  });

  return (
    <mesh
      {...props}
      ref={ref}
      scale={2}
      //onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
      >
      <sphereGeometry args={[1, 32, 32]}/>
      <shaderMaterial transparent attach="material" key={Math.random()} args={[props.shader]}/>
    </mesh>
  )
}


function App() {
  const [color, setColor] = useState([0.3, 0.2, 0.8]);
  const [shader, setShader] = useState({uniforms: {u_time: { type: "f", value: 0 }, color : {type: "vec3", value: [0.3, 0.2, 0.8]}}, vertexShader: vertexShader, fragmentShader: fragmentShader});

  async function setShaderColor(r, g, b){
    setShader({uniforms: {u_time: { type: "f", value: 0 }, color : {type: "vec3", value: [r, g, b]}}, vertexShader: vertexShader, fragmentShader: fragmentShader})
  }

  return (
    <div id="wrapper">
      <div className="overlay"></div>
      <div className="hero" id="main-bg">
          <div className="hero-wrapper">
              <Routes>
                <Route path="/" element={<Landing/>}/>
                <Route path="/projects" element={<Projects changeColor={(r, g, b) => {setShaderColor(r, g, b)}}/>}/>
                <Route path="/contact" element={<Contact/>}/>

                <Route path="/starfire" element={<Starfire changeColor={(r, g, b) => {setShaderColor(r, g, b)}}/>}/>
                <Route path="/novacaine" element={<Novacaine changeColor={(r, g, b) => {setShaderColor(r, g, b)}}/>}/>
                <Route path="/exttasy" element={<Exttasy changeColor={(r, g, b) => {setShaderColor(r, g, b)}}/>}/>
                <Route path="/opium" element={<Opium changeColor={(r, g, b) => {setShaderColor(r, g, b)}}/>}/>
                <Route path="/oxygen" element={<Oxygen changeColor={(r, g, b) => {setShaderColor(r, g, b)}}/>}/>
                <Route path="/*" element={<NotFound/>}/>
              </Routes>
              <div className="hero-right">
                  <div className="canvas-temp">
                  <Canvas>
                      <Sphere position={[0, 0, 0]} color={color} shader={shader} />
                  </Canvas>
                  </div>
              </div>
          </div>
        </div>
    </div>
  );
}

export default App;
