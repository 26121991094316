import './App.css'
import { Canvas, useFrame } from '@react-three/fiber'
import React, { useRef} from 'react'
import {Link} from 'react-router-dom'

function Landing() {
    return (
      <div className="hero-left">
          <h1 className="hero-title">Adam Miszczak</h1>
          <p className="hero-sub">London-based software developer specializing in backend and native application development, primarily using C, C++ and Java.</p>
          <div className="hero-actions">
            <Link to="/projects"><div className="theme-button">View Projects</div></Link>
            <Link to="/contact"><p className="text-link" style={{marginLeft: "2rem"}}>Contact</p></Link>
          </div>
      </div>
    );
  }
  
  export default Landing;