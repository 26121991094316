import { Link } from "react-router-dom"

function Contact(){
    return (
        <div className="hero-left">
            <h1 className="hero-title">Contact</h1>
            <p className="hero-sub">For all enquiries: <br/><a href="mailto:adam.miszczak36@gmail.com">adam.miszczak36@gmail.com</a></p>
            <div className="hero-actions">
                <Link to="/"><div className="theme-button">Back to Home</div></Link>
            </div>
        </div>
    )
}

export default Contact