import './App.css'
import { Canvas, useFrame } from '@react-three/fiber'
import React, { useRef} from 'react'
import {Link} from 'react-router-dom'
import { useEffect, useState, useCallback } from 'react'
import ImageViewer from 'react-simple-image-viewer';
import nvc from './res/nvc.png'

function Novacaine(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
      nvc
    ];

    const openImageViewer = useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };

    useEffect(() => {
      props.changeColor(0.59, 0.55, 0.86);
      document.getElementById("main-bg").classList.add("novacaine-bg");
    }, [])

    return (
      <div className="hero-left">
          <h1 className="hero-title">Novacaine</h1>
          <p className="hero-sub">Novacaine is a low-level, imperative programming language written in C++. Novacaine has a custom code-generation backend which produces x64 assembly.</p>
          <div className="hero-actions hero-gallery">
              <div className="gallery">
                {images.map((src, index) => (
                  <img
                    src={ src }
                    onClick={ () => openImageViewer(index) }
                    key={ index }
                    style={{ margin: '2px' }}
                    alt=""
                    className='gallery-img'
                  />
                ))}

                {isViewerOpen && (
                  <ImageViewer
                    src={ images }
                    currentIndex={ currentImage }
                    disableScroll={ false }
                    closeOnClickOutside={ true }
                    onClose={ closeImageViewer }
                  />
                )}
            </div>

            <div className="action-container">
              <Link to="/projects"><div className="theme-button">Back to Projects</div></Link>
            </div>
          </div>
      </div>
    );
  }
  
  export default Novacaine;